import React from 'react'
import {Link} from 'react-router-dom'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import logo from '../../assets/nav-logo.png'
import './FooterStyles.css'
import DHCS from "../../assets/DHCS-logo2.png";
import JointCommission from "../../assets/joint_commission_logo.webp";

import { Link as LinkRoll } from 'react-scroll'

const Footer = () => {
    return (

<>
        <div className='footer'>
            <div className="container">
                <div className="top">
                    <div className="logo-footer">
                    <img src={logo} width="120px" height="80px" alt="Natural Rest House Logo" loading="lazy"/>
                    </div>
                    <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                        <BsFillArrowUpCircleFill className='footer-icon' />
                    </LinkRoll>

                </div>
                <div className="col-container">

                    <div className="col">
                        <h3>COMPANY</h3>

                        <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                        <Link to='/mission'><p>Our Mission</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                        <Link to='/team'><p>Our Team</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                        <Link to='/testimonials'><p>Testimonials</p></Link>
                        </LinkRoll>

                    </div>


                    <div className="col">
                        <h3>Programs</h3>
                        
                        <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                        <Link to='/mental-health'><p>Mental Health Treatment</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                        <Link to='/substance-abuse'><p>Substance Abuse Treatment</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                        <Link to='/jobs'><p>Jobs Program</p></Link>
                        </LinkRoll>

                    </div>

                    <div className="col ">
                        <h3>Quick Links</h3>
                        
                            <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                                <Link to='/'><p>Home</p></Link>
                            </LinkRoll>

                            <LinkRoll activeClass="active" to="toTop" spy={true} smooth={true} duration={500} >
                                <Link to='/contact'><p>Contact Us</p></Link>
                            </LinkRoll>

                    </div>
                </div>




                <div className="certification">

                  <div className="certificationLeft">


                        <p>Licensed by the State Department of Health Care Services</p>
                        <div className='certificationLeftImage'>
                            <img src={DHCS} alt="DHCS Logo" loading="lazy" />
                        </div>


                        <p>
                        La Quinta, CA 92253:<br />
                        Certification number: 330161BP <br />Certification Expiration date: 12/31/24
                        <br />
                        <a href="https://data.chhs.ca.gov/dataset/sud-recovery-treatment-facilities"
                        target="_blank"
                        rel="noreferrer">( View License & Certification Information here ).</a>
                        </p>


<br />

<p>
                        Los Angeles CA 90011:<br />
                        Certification number: 191223AP <br />Certification Expiration date: 12/31/25
                        <br />
                        <a href="https://data.chhs.ca.gov/dataset/sud-recovery-treatment-facilities"
                        target="_blank"
                        rel="noreferrer">( View License & Certification Information here ).</a>
                        </p>




                    </div>

                    

                    <div className="certificationRight">
                        

                    <p>Natural Rest House is accredited by The Joint Commission and have been awarded its Gold Seal of Approval™</p>
                    <div className='certificationRightImage'>
                        <img src={JointCommission} alt="Joint Commission Logo" loading="lazy"/>
                    </div>


                    </div>
                    

                  </div>








                <p className='copyright'><p>&copy; 2023 Natural Rest House Recovery. All rights reserved</p></p>


            </div>

        </div>
</>
    )
}

export default Footer
