import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import Fade from 'react-reveal/Fade';
import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState('Submit');
    const [submitted, setSubmitted] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setButtonText('Sending...');  // Change button text to 'Sending...'

        emailjs.sendForm('service_bdfl0ie', 'template_wbgd7je', form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                setSubmitted(true);
                setButtonText('Message Sent');  // Change button text to 'Message Sent' after success
            }, (error) => {
                console.log(error.text);
                setButtonText('Submit');  // Revert button text if there's an error
            });

        setTimeout(() => {
            setSubmitted(false);
            setButtonText('Submit');  // Reset button text after a few seconds
        }, 3000);

        e.target.reset();  // Reset the form fields
    };

    return (
        <>
            <section className="contact-container">
                <Fade left>
                    <div className="contact-cards-container">
                        <div className="get_form_inner2">
                            <h2>CALL US</h2>
                            <p><AiOutlinePhone /><a href="tel:3237920399"> +1 (323) 792-0399</a></p>
                        </div>

                        <div className="get_form_inner2">
                            <h2>EMAIL</h2>
                            <p><AiOutlineMail /> info@naturalresthouse.com  </p><br />
                        </div>

                        <div className="get_form_inner2">
                            <h2>LOCATION</h2>
                            <p><BsFillPinMapFill /> 79100 Ocotillo Dr La Quinta, CA 92253</p>
                        </div>

                        <div className="get_form_inner2">
                            <p><BsFillPinMapFill /> 1045 E 20th St Los Angeles, Ca 90011</p>
                        </div>
                    </div>
                </Fade>

                <div className="get_form_inner">
                    <div className="get_form_inner_text">
                        <h3>Get In Touch</h3>
                        <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="inputs">
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Your Name" 
                                required 
                            /><br />
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Your Email" 
                                required 
                            /><br />
                            <input 
                                type="tel" 
                                name="phone" 
                                placeholder="Your Phone" 
                                pattern="[0-9]{10}" 
                                title="Phone number should be 10 digits" 
                                required 
                            /><br />
                            <input 
                                type="text" 
                                name="subject" 
                                placeholder="Subject" 
                                required 
                            /><br />
                        </div>

                        <div>
                            <textarea 
                                name="message" 
                                placeholder="How can we help?" 
                                cols="50" 
                                rows="8" 
                                required
                            ></textarea>

                            <input 
                                type="submit" 
                                value={buttonText} 
                                disabled={submitted}  // Disable button after submission
                            />
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default ContactForm;
